import logo from "./logo.png"

const clientData = {
    client_entity: 59,
    attorney_firm: 'Houston Heights Bankruptcy',
    attorney_name: 'Eric Southward',
    attorney_number: '317-979-3747',
    attorney_email: 'eric.southward@gmail.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_eric_southward+(720p).mp4',
    mainColor: '#242424',
    secondaryColor: '#0000a0',
    siteLink: 'http://www.houstonheightsbankruptcy.com/',
    logo
}

export default clientData